import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './gallery-preview.module.css'

export default ({ gallery }) => (


  <div className={styles.preview}>

      { gallery.coverImage ? (
          <Link className={styles.galleryLink} to={`/gallery/${gallery.slug}`}>
              <Img alt="" fluid={gallery.coverImage.fluid} />
              <h3 className={styles.previewTitle}>
                  {gallery.title.title}
              </h3>
          </Link>
      ) : <></> }

    <p
      dangerouslySetInnerHTML={{
        __html: gallery.description.childMarkdownRemark.html,
      }}
    />
    { gallery.tags && gallery.tags.length > 0 && (
      gallery.tags.map(tag => (
        <p className={styles.tag} key={tag}>
          {tag}
        </p>
      ))
    ) }
  </div>
)
