import React, {Fragment} from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import LayoutSimple from '../components/layout-simple'
import styled from 'styled-components';
import heroStyles from '../components/hero.module.css'
import GalleryPreview from "../components/gallery-preview";
const GalleryItem = styled('li')`
`;
class ContentPageTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const galleries = get(post, 'gallery');
    console.log(galleries);
    return (
      <LayoutSimple location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <div >
            <Img  alt={post.title} fluid={post.heroImage.fluid} />
          </div>
          <div className="wrapper post-content page-template no-image">
              <article className={"post-content-body"}>
                <h1 className="section-headline">{post.title}</h1>

                <div
                    dangerouslySetInnerHTML={{
                      __html: post.description.childMarkdownRemark.html,
                    }}
                />



                <p
                  style={{
                    display: 'block',
                  }}
                >

                </p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />

              </article>
            </div>


          <div className={"wrapper"}>
            {  galleries && galleries.length > 0 &&

              <Fragment>
                <h4>Related Image Galleries</h4>
                <ul className="gallery-list">
                  {galleries.map(( node ) => {
                    return (
                        <GalleryItem key={node.slug}>
                          <GalleryPreview gallery={node} />
                        </GalleryItem>
                    )
                  })}
                </ul>
              </Fragment>
            }

          </div>

          </div>
      </LayoutSimple>
    )
  }
}

export default ContentPageTemplate

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ) {
      id
      title
      slug
      gallery {        
        id
        slug
        title {
          id
          title
        }
        coverImage {
          fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        description {
          id
          childMarkdownRemark {
            html
          }
        }
       

      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1440,  quality: 100, resizingBehavior: SCALE, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
